.cardContainer {
  padding: 0.75rem;
  border-radius: 10px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25);
  width: 12rem;
  height: 15rem;
  background-color: white;

  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.cardContainer img {
  height: 100%;
  width: 100%;
}

.cardContainer h3 {
  font-size: var(--font-regular);
}
