main {
  width: 75%;
  max-width: 65rem;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 5rem;
  padding: 7rem 0;
}

/*** Top landing section ***/

#landing {
  display: grid;
  gap: 2rem;
  grid-template-areas:
    "headline headline ."
    "about img img";
}

#landing .headline {
  font-weight: 500;
  grid-area: headline;
  font-size: var(--font-xlarge);
}

#landing .headline > span {
  font-weight: bold;
  color: var(--purple-regular);
}

#landing img {
  grid-area: img;
  margin-top: -2rem;
}

article.about-me {
  grid-area: about;
  text-align: justify;
  height: fit-content;
}

/***************************/

/*** Projects section ***/

.projects-section {
  position: relative;
}

.blue-box {
  background-color: var(--light-blue);
  width: 200vw;
  position: absolute;
  transform: translateX(-50%);
  top: -2rem;
  bottom: -2rem;
}

.project-cards {
  z-index: 2;
  margin-top: 1rem;
  display: flex;
  gap: 2rem;
  justify-content: center;
  position: relative;
}

/************************/

/*** Experience section ***/

.experience-accordions {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: center;
  margin-top: 1rem;
}

/**************************/
