.accordion {
  width: 75%;
  margin: auto;
  height: 2.5rem;
  transition: height ease-out 200ms;
  background-color: var(--purple-dark);
  color: white;
  border-radius: 10px 10px;
}

.accordion img {
  transition: transform linear 100ms;
}

.accordion-content p {
  padding: 1rem;
}

.accordion h3 {
  border-bottom: 2px solid var(--light-blue);
}

.accordion button {
  /* remove default button styles */
  color: inherit;
  border: none;
  width: 100%;
  height: 100%;
  margin: 0;
  background-color: transparent;
  font: inherit;
  cursor: pointer;

  display: flex;
  align-items: center;
  gap: 0.25rem;

  padding: 0.5rem;
}

.accordion.expanded {
  height: 10rem;
}

.accordion.expanded img {
  transform: rotate(90deg);
}
